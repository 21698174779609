
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"


import moduleOrderList from "./modules/moduleOrderList"
import moduleNotification from './modules/moduleNotification'
Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        orderList:moduleOrderList,
        notification:moduleNotification
    },
    strict: process.env.NODE_ENV !== 'production'
})

