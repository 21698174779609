
import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
        // =============================================================================
        // MAIN LAYOUT ROUTES
        // =============================================================================
            path: '',
            redirect: '/',
            component: () => import('./layouts/Main.vue'),
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@views/Dashboard.vue'),
                    meta: {
                      breadcrumb: [
                        {
                          text: "Dashboard",
                          active: true
                        }
                      ],
                      pageTitle: 'Welcome!',
                      authRequired: true,
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: () => import('@views/account/UserEdit.vue'),
                    meta: {
                       breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{path:"/dashboard"}
                          },
                          {
                            text: "Account",
                            active: true
                          }
                      ],
                      pageTitle: 'Profile',
                      authRequired: true,
                    },
                },
                {
                    path: '/orders',
                    name: 'order',
                    component: () => import('@views/orders/OrderList.vue'),
                    meta: {
                      breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{path:"/dashboard"}
                          },
                          {
                            text: "Orders",
                            active: true
                          }
                      ],
                      pageTitle: "Orders List",
                      authRequired: true,
                    },
                },
                {
                    path: '/orders/:orderId(\\d+)',
                    name: 'order-view',
                    component: () => import('@views/orders/show/OrderShow.vue'),
                    meta: {
                        breadcrumb: [
                          {
                            text: "Orders",
                            to:{ path:"/orders"}
                          },
                          {
                            text: "Show",
                            active: true
                          }
                      ],
                      pageTitle: "View Order",
                      authRequired: true,
                    },
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () => import('@views/notifications/NotificationList.vue'),
                    meta: {
                        breadcrumb: [
                          {
                            text: "Dashboard",
                            to:{path:"/dashboard"}
                          },
                          {
                            text: "Notifications List",
                            active: true
                          }
                      ],
                      pageTitle: "Notifications",
                      authRequired: true,
                    }
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/FullPage.vue'),
            children: [
                {
                    path: '/error-404',
                    name: 'error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                },
                {
                    path: '/error-500',
                    name: 'error-500',
                    component: () => import('@/views/pages/Error500.vue'),
                },
                {
                    path: '/not-authorized',
                    name: 'not-authorized',
                    component: () => import('@/views/pages/NotAuthorized.vue'),
                },
                {
                    path: '/auth',
                    name: 'auth',
                    component: () => import('@/views/pages/Authenticate.vue'),
                },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        },

    ],
});


(function () {
    const appLoading = document.getElementById('applicationBusyPage')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})();

router.afterEach(() => {
    // Remove initial loading
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn()
    if(to.meta.authRequired) {
      if (!isLoggedIn) return next({path: '/auth', query:{ token: to.query.token, intended: to.path }});
    }
    if(to.meta.guestRequired) {
      if (isLoggedIn) return next({path: '/' });
    }
    return next();
});

export default router
