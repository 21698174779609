
<template>
	<div id="app" :class="vueAppClasses">
		<router-view />
	</div>
</template>

<script>

export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import '@assets/scss/app';
</style>
