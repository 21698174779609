import state from "./state";

export default {
    LOGOUT_USER(state) {
        state.activeUser = {};
        localStorage.removeItem(state.localUserKey);
        window.location = `${state.domain}/logout`
    },
    TOGGLE_LOADER(state, loader) {
        state.layout.loader = loader;
    },
    UPDATE_USER_INFO(state, user) {
        if(!user.id){return};
        state.activeUser = {...user};
        const get_localUser = localStorage.getItem(state.localUserKey);
        const storedData = JSON.parse(unescape(atob(get_localUser)));
        storedData.user = user;
        localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
    },

}
