import 'regenerator-runtime/runtime'
// AutoLogin/Out Calls
import "@/auth/authService"

import Vue from 'vue'
import App from './App.vue'

// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

// Install BootstrapVue & Optionally BootstrapVue icon components plugin
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import './plugins/toastification.js'
import './plugins/sweetalert2.js'
import './plugins/vee-validate.js'

// Globally Registered Components
import './globalComponents.js'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Filters
import './filters/filters'

// Mixins
import mixins from './mixins/mixins'
Vue.mixin(mixins);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
