import axios from "@/axios.js"

const actions = {
    fetchOrders({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get("/orders")
            .then((response) => {
              if(response.data.success){
                commit('SET_ORDERS', response.data.orders)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
      fetchOrder({ commit }, orderId) {
        return new Promise((resolve, reject) => {
          axios.get(`/orders/${orderId}/show`)
            .then((response) => {
              if(response.data.success){
                commit('SET_ORDER', response.data.order)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
      fetchMessages({ commit }, orderId) {
        return new Promise((resolve, reject) => {
          axios.get(`/order-messages/${orderId}/show`)
            .then((response) => {
              if(response.data.success){
                  commit('SET_MESSAGES', response.data.messages)
               }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
      },
}

const getters = {
    getMessages: (state, getters, rootState) => {
      let messages = state.order.messages.filter((msg) => msg.message.toLowerCase().includes(state.messageSearchQuery.toLowerCase()))
      return messages;
  },
}

const mutations = {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_ORDER(state, order){
      state.order = order
      if(state.orders.length){
        const dataIndex = state.orders.findIndex((o) => o.id == order.id);
        Object.assign(state.orders[dataIndex], order);
      }
    },

    /**************** THIS IS FOR MESSAGES********************/
    SET_MESSAGE_SEARCH_QUERY(state, query) {
      state.messageSearchQuery = query
    },
    SET_MESSAGES(state, messages){
      state.order.messages = [...messages]
    },
    ADD_MESSAGE(state, message){
      state.order.messages.push(message)
    },
    UPDATE_MESSAGE(state, message){
      const dataIndex = state.order.messages.findIndex((a) => a.id == message.id);
      Object.assign(state.order.messages[dataIndex], message);
    },
    DELETE_MESSAGE(state, messageId){
      const itemIndex = state.order.messages.findIndex((item) => item.id == messageId)
        state.order.messages.splice(itemIndex, 1)
    },
}

const state = {
  orders: [],
  order: {},
  messageSearchQuery:""
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

