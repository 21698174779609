export default{
    methods:{
        dropzoneTemplate: function () {
            return ` <div class="dropzone-previews mt-3">
              <div class="card mt-1 mb-0 shadow-none border">
                  <div class="p-2">
                      <div class="row align-items-center">
                          <div class="col-9">
                              <img data-dz-thumbnail src="#" width="100" class="rounded bg-light" alt="">
                          </div>
                          <div class="col-3">
                            <!-- Button -->
                            <a href="" class="btn btn-link text-muted" data-dz-remove>
                                <i class="mdi mdi-close"></i>
                            </a>
                        </div>
                          <div class="col">
                              <a href="javascript:void(0);" class="text-muted text-truncate d-block font-weight-bold" data-dz-name></a>
                              <p class="mb-0" data-dz-size></p>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
          `;
        },
        calculateDeliveryTime(time){
            if(!time){return}
            return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
        },
        pageloader(action){
            let loader = document.getElementById('applicationBusyPage');
            if(loader){
                action == "stop" ? loader.classList.add("d-none") : loader.classList.remove("d-none");
            }
        },
        alertSuccess(text){
            toastr.success(text, 'Success');
        },
        alertError(text){
            toastr.error(text, 'Something went worry', {timeOut: 3000});
        },
    }
}
