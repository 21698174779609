
import axios from "@/axios.js"

const actions = {

    changeLoaderValue({ commit }, loader = false) {
        commit("TOGGLE_LOADER", loader)
    },

    fetchActiveUser({ commit }) {
        return new Promise((resolve, reject) => {
          axios.get("/account/show")
            .then((response) => {
              if(response.data.success){
                commit('UPDATE_USER_INFO', response.data.user)
              }
              resolve(response)
            }).catch((error) => { reject(error) })
        })
    },
    logoutUser({ commit, rootState }, action) {
        commit("LOGOUT_USER")
        return new Promise((resolve, reject) => {
          axios.post(`${rootState.domain}/api/logout`)
          .then((response) => {
              resolve(response)
          }).catch((error) => { reject(error) })
        })
    },
}

export default actions
